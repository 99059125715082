import React, { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { LanguageCode } from '../core-types'
import { LOCALE_COOKIE_NAME } from './constants'
import { IntlConfig } from 'react-intl'

const LocaleContext = createContext<{
  messagesCache: Record<string, Record<string, string>>
  defaultLocale: LanguageCode
  locale: LanguageCode
  setLocale: (locale: LanguageCode) => void
}>({
  messagesCache: {},
  defaultLocale: LanguageCode.en,
  locale: LanguageCode.en,
  setLocale: () => {
    // noop
  },
})

export const LocaleProvider = ({
  defaultLocale,
  defaultMessages,
  children,
}: {
  defaultLocale: LanguageCode
  defaultMessages?: Record<string, string>
  children: React.ReactElement
}) => {
  const messagesCache: Record<string, Record<string, string>> = {}
  if (defaultMessages) {
    messagesCache[defaultLocale] = defaultMessages
  }
  const router = useRouter()
  const localStorageLocale =
    typeof window !== 'undefined' &&
    window.localStorage &&
    window.localStorage.getItem(LOCALE_COOKIE_NAME)
  if (
    localStorageLocale === LanguageCode.en ||
    localStorageLocale === LanguageCode.it ||
    localStorageLocale === LanguageCode.nb
  ) {
    defaultLocale = localStorageLocale
  }
  let [locale, setLocale] = useState(defaultLocale)

  // If locale url parameter is set, use this
  if (router.query.locale) {
    locale = router.query.locale as LanguageCode
  }

  return (
    <LocaleContext.Provider
      value={{
        locale,
        defaultLocale,
        setLocale: (newLocale) => {
          if (locale === newLocale) {
            return
          }
          try {
            window.localStorage.setItem(LOCALE_COOKIE_NAME, newLocale)
          } catch (err) {
            // could not update locale
          }
          setLocale(newLocale)
        },
        messagesCache,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export function useLocale(): {
  locale: LanguageCode
  defaultLocale: LanguageCode
  setLocale: (locale: LanguageCode) => void
  loading: boolean
  messages: IntlConfig['messages']
} {
  const { locale, defaultLocale, setLocale, messagesCache } = useContext(
    LocaleContext
  )
  const [{ loading, messages }, setState] = useState({
    loading: !Boolean(messagesCache[locale]),
    messages: messagesCache[locale] || {},
  })
  useEffect(() => {
    async function fetchMessages() {
      if (!messagesCache[locale]) {
        const result = await fetch(
          `/locale/${locale}.json?${process.env.BUILD_ID}`
        ).then((res) => res.json())
        setState({ loading: false, messages: result })
        messagesCache[locale] = result
      }
    }
    fetchMessages()
  }, [locale])
  return { locale, defaultLocale, setLocale, loading, messages }
}
