import React from 'react'
import { Content, Wrap } from './styled'
import { Loading } from '../'

export default function PageLoading() {
  return (
    <Wrap>
      <Content center>
        <Loading />
      </Content>
    </Wrap>
  )
}
